$(function ($) {

    // Collapse   
    var $click = $('.page-content__title');

    if ($(window).width() > 576) {
        $('.page-content__title').addClass('page-content__title--open');
        $('.page-content__collapse').addClass('page-content__collapse--open');
    };

    $click.click(function () {
        if ( $(this).parent().next().hasClass('page-content__collapse--open') ) {
            $(this).parent().next().removeClass('page-content__collapse--open');
            $(this).removeClass('page-content__title--open');
        } else {
            $(this).parent().next().toggleClass('page-content__collapse--open');
            $(this).toggleClass('page-content__title--open');
        }
    });

});