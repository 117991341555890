$(function () {

    /* Ilość porcji */
    var qty = '#qty'; // input
        n = 10; // ilość
    
    /* Filter */
    $(qty).keyup(function () {
        $(qty).val(this.value.match(/[0-9]*/));
        if ($(qty).val() <= 0) {
            $(qty).val(1);
        }
    });

    /* Ustawiamy ilośc porcji */
    $(qty).val(n);

    /* Click + */
    $(document).on('click', '.btn-plus', function () {
        $(qty).val(++n);
    });

    /* Click - */
    $(document).on('click', '.btn-minus', function () {
        if (n >= 2) {
            $(qty).val(--n);
        } else {
        }
    });
    
    /* Load click */
    $(document).ready(function () {
        $(".js-init-calculation").click();
    });
    
    $(document).on('change keyup click', '#qty, .btn-plus, .btn-minus, .js-click-delete, .js-summa', function () {
        $(".js-init-calculation").click();
    });
    
    $(document).on('click', '.js-init-calculation', function () {
        var $quantity = $('#qty').val();
            $ingredient_price = $(this).val();
            $ingredient_value = $(this).parent().next().find(".js-ingredient-amount").val();
            $ingredient_portion_value = $(this).parent().next().find(".js-ingredient-amount-default").val();
                

        $ingredient_price = parseFloat($ingredient_price.replace(',', '.'));

        $(this).parent().next().next().find("input").val($ingredient_value);

        $ingredient_portion_value = $ingredient_portion_value / 10;
        $ingredient_portion_value = $ingredient_portion_value * $quantity;
        // $ingredient_portion_value = $ingredient_portion_value.toFixed(3);
        // console.log($(this).parent().next().find('.js-ingredient-unit').val());
        if ($(this).parent().next().find('.js-ingredient-unit').val() != 'szt.') { 
            $ingredient_portion_value = $ingredient_portion_value.toFixed(3);
        }

        $(this).parent().next().find(".js-ingredient-amount").val($ingredient_portion_value)

        var total_row = $ingredient_portion_value * $ingredient_price;
            total_row = (Math.round(total_row * 100) / 100); //-> "1.1"
            total_row = total_row.toFixed(2); // miejsca po przecinku

        $(this).parent().next().next().find("input").val(total_row);
		
        var ingredient_total_price = 0; // ingredient total price
            recipe_cost = 0; // recipe total price
            recipe_weight = 0; // recipe weight
            ingredient_total_row = $(this).parent().next().next().find("input");
        
        $(ingredient_total_row).each(function () {
            var k = parseFloat($(this).val());
            if (!k || k == 0) { k = 0; }
            ingredient_total_price += k;
            ingredient_total_price = parseFloat(ingredient_total_price).toFixed(2);
            ingredient_total_price_text = parseFloat(ingredient_total_price).toFixed(2);
        })
        
        ingredient_total_price_text = String(ingredient_total_price_text).replace(".", ",")
        $(this).parents(".ingredient").find(".ingredient-total-cost").text(ingredient_total_price_text + " zł");
        $(this).parents(".ingredient").find(".ingredient-total-cost-input").val(ingredient_total_price);

        $(".ingredient .ingredient-total-cost-input").each(function () {
            var t = parseFloat($(this).val());
            if (!t || t == 0) { t = 0; }
            recipe_cost += t; // total summa
            recipe_cost = recipe_cost;
        })

        // Waga
        $(".ingredient .js-ingredient-amount-default-one").each(function () {
            
            // console.log($(this).parent().find('.js-ingredient-unit').val());
            if ($(this).parent().find('.js-ingredient-unit').val() != 'szt.') {
                var w = parseFloat($(this).val());
                if (!w || w == 0) { w = 0; }
                recipe_weight += w; // total summa
            }
            // recipe_weight = parseFloat(recipe_weight).toFixed(3);
        })

        var servings = $quantity;
            serving = recipe_cost / servings;
            serving = parseFloat(serving).toFixed(2);
        
        // serving = String(serving).replace(".", ",")
        $(".serving_cost").text(parseFloat(serving).toFixed(2).replace('.', ','));
        $(".serving_cost_input").val(parseFloat(serving).toFixed(2).replace('.', ','));
		
        // total summa
        // recipe_cost = String(recipe_cost).replace(".", ",")
        $(".total").text(parseFloat(recipe_cost).toFixed(2).replace('.', ','));
        $(".total_input").val(parseFloat(recipe_cost).toFixed(2).replace('.', ','));
        
        // total weight
        // recipe_weight = String(recipe_weight).replace(".", ",")
        recipe_weight = recipe_weight * 1000;
        recipe_weight = parseFloat(recipe_weight).toFixed(2).replace('.', ',')
        $(".allWeight").text(recipe_weight + ' g');
        $(".ingredients_total_weight").val(recipe_weight);
        
        // TCPDF
        $('#portion_pdf').val($quantity);
    });
	
});

// miejsca po przecinku
Number.prototype.Crop = function (x){
	var s = this+'', a = s.split('.');
	a[1]=a[1]||'';      
    return parseFloat(a[0] + '.' + a[1].substring(0, x));
}