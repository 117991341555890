(function ($) {

    // Image position
    $(function () {
        $(window).resize(function () {
            clearTimeout(window.resizedFinished);
            window.resizedFinished = setTimeout(function () {
                setSliderPosition();
            }, 250);
        });

        function setSliderPosition() {
            if ($('.col-width').length) {
                var eLeft = $('.col-width').offset().left;
            };
            var height = $('#top').outerHeight();

            $(".item__image").css('height', height + 185).css('left', eLeft + 15);
        }

        if ($('.col-width').length) {
            setSliderPosition();
        }
    });

    // Scroll
    $(document).ready(function () {
        $(".btn-scroll").click(function (event) {
            event.preventDefault();

            var id = $(this).attr('href'),
                top = $(id).offset().top;
    
            $('body,html').animate({ scrollTop: top }, 500);
        });
    });

    $("#check_all").click(function () {
        $(".form-check-input__child").prop('checked', $(this).prop('checked'));
    });
    
    $(".form-check-input__child").change(function(){
        if (!$(this).prop("checked")){
            $("#check_all").prop("checked",false);
        }
    });

    var ingredient_checkbox = $('.checkbox-group input[type="checkbox"]');

    ingredient_checkbox.on('click', function () {
        if ($(this).prop('checked')) {
            $(this).parent().parent().next().children().attr('disabled', false);
            $(this).parent().parent().next().next().children().attr('disabled', false);
        } else { 
            $(this).parent().parent().next().children().attr('disabled', true).val('');
            $(this).parent().parent().next().next().children().attr('disabled', true).val('');
        }
    });

})(jQuery);